.survey-container {
    font-family: 'Arial', sans-serif;
    background-color: #f0efef;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
  }
  
  .survey-container h1 {
    text-align: center;
    font-size: 24px;
    color: #333;
  }
  
  .survey-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .submit-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #45a049;
  }
  
  .adr-section {
    background-color: #f1f1f1;
    border-radius: 8px;
  }
  
  .adr-question {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .adr-label {
    font-size: 16px;
  }

  .severity-options {
    display: flex;
    justify-content: space-between; /* Distribute buttons evenly across the row */
    margin: 10px 0;
    width: 100%; /* Ensure the options take the full width */
    gap: 50px; /* Increased gap between the buttons */
  }
  
  .severity-options button {
    flex: 1; /* Make each button take up equal width */
    padding: 8px 0; /* Adjusted padding for a better button size */
    font-size: 14px;
    color: #fff;
    background-color: #2196F3;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
    min-width: 50px; /* Ensure buttons have a minimum width */
  }
  
  .severity-options button:hover {
    background-color: #1b79c7;
  }
  
  .severity-options button.active {
    background-color: #145991;
  }
  
  .question {
    display: flex;
    flex-direction: column;
  }
  
  .question p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .question label {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
  }
  
  .question input[type="radio"] {
    margin-right: 8px;
  }
  