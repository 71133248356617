@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

.nav-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px; 
  background-color: #ffffff;
  color: #6C6C6C;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  height: 50px; 
}

.nav-greeting {
  font-size: 24px;
  color: #7292E0;
  font-weight: bold; 
  text-align: left; 
  font-family: 'Roboto', sans-serif; 

}

.nav-icons {
  display: flex;
  align-items: center;


}

.navBaricon-button {
  background: none;
  border: none;
  padding: 10px 15px; 
  cursor: pointer;
  display: flex; 
  align-items: center;
  border-radius: 40px; 
  color: #7292E0; 

}

.navBaricon-button svg {
  width: 1.3em;
  height: 1.3em;

}

.navBaricon-button.active {
  background-color: #72E0C5; 
  border-radius: 40px; 
  color: white; 
}

