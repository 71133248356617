.containerPage {
  display: flex;
  height: 100vh;
}

.content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.nav-bar {
  position: sticky;
  top: 0; /* Adjust as needed if you have any top bar or padding */
  z-index: 1000; /* Ensures the navbar stays on top of other content */
}

.main-content {
  overflow-y: auto;
  height: 100%; /* Adjust height as necessary */
}