@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

.sidebar {
  width: 250px;
  height: auto;
  background-color: #ffffff;
  color: #333;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  font-family: "Roboto", sans-serif;
  padding: 20px;
  font-weight: 600;
}

.sidebar-header {
  text-align: center;
}

.sidebar-image {
  width: 100%;
  height: auto;
  border-radius: 25px;
}

.sidebar-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-menu li a {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #7292e0;
  transition: background-color 0.3s, border-radius 0.3s;
  --icon-color: #7292e0;
}

.sidebar-icon {
  fill: currentColor;
  width: 1.3em;
  height: 1.3em;
  margin-right: 8px;
}

.sidebar-menu li a.active {
  background-color: #72e0c5;
  --icon-color: #72e0c5;
  border-radius: 20px;
  color: white;
}

.sidebar-menu li a.logout {
  color: #bad3e3;
  border-radius: 20px;
}

.sidebar-menu li a.no-active {
  background-color: transparent !important;
  color: #bad3e3 !important;
}

.divider-line {
  height: 2px;
  background-color: #7292e0;
  margin: 20px auto;
  width: 90%;
}

.sidebar-menu li button.tc {
  color: #bad3e3;
  background: none;
  border: none;
  font-size: 12px;
  cursor: pointer;
  text-align: left;
  padding: 0;
}
.sidebar-menu li button.tc:hover {
  text-decoration: underline;
}