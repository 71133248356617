/* TermsAndConditions.css */

.terms-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f0efef;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    font-size: 2rem;
    color: #333;
    text-align: center;
  }
  
  .terms-content {
    height: 70vh;
    overflow-y: auto;
    border: 1px solid #ccc;
    background-color: #fff;
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
    padding: 10px 20px;
  }
  
  h2 {
    color: #007bff;
    margin-top: 20px;
    font-size: 1.25rem;
  }
  
  .accept-button {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease;
  }
  
  .accept-button:hover {
    background-color: #0056b3;
  }
  
  /* Responsive adjustments */
  @media (max-width: 600px) {
    .terms-container {
      padding: 15px;
    }
  
    h1 {
      font-size: 1.5rem;
    }
  
    h2 {
      font-size: 1.1rem;
    }
  
    .terms-content {
      font-size: 0.9rem;
    }
  
    .accept-button {
      width: 100%;
      font-size: 1rem;
      padding: 12px 0;
    }
  }
  
  .accept-message {
    text-align: center;
    margin-top: 50px;
  }
  
  .accept-message h1 {
    color: green;
  }
  
  .accept-message p {
    font-size: 1.2em;
    color: #333;
  }