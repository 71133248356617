@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #eeeeee; 
}


h1{
    font-family: 'Roboto', sans-serif;
    color:#95B9DB;

}

.login-form {
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    background: white;
    display: flex;
    flex-direction: column;
    width: 400px;
    font-family: 'Roboto', sans-serif;


}


  
  .login-form input[type="text"],
  .login-form input[type="password"] {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    font-family: 'Roboto', sans-serif;

  }
  
  .login-form .remember-me {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

  }
  .error-message{
    font-family: 'Roboto', sans-serif;
  color: red;
  font-size: 12px;

  }
  
  .login-form .remember-me input[type="checkbox"] {
    margin-right: 5px;
    color: rgb(108, 108, 108);
 
  }
  
  label{
    text-decoration: none;
    font-size: 13px;
    color: rgb(108, 108, 108);
  }

  .login-form button {
    padding: 12px;
    background-color: #95CFDB; 
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    width:150px;
    align-self: center;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
  }

  #caretakerButton{
    padding: 12px;
    background-color: #95B9DB; 
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    width:210px;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    float:left;

  }
  #caretakerButton:hover {
    background-color: #95DBA1; 
  }
  
  .login-form button:hover {
    background-color: #95DBA1; 
  }
  
  .login-form a {
    color: rgb(108, 108, 108);
    text-decoration: none;
    text-align: center;
    margin-top: 15px;
    font-size: 12px;
  }
  
  #h1caretaker{
    font-family: 'Roboto', sans-serif;
    color: #797575;;
    font-size: 15px;
  }

  .forgot-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #eeeeee; 
}

.security-question {
  margin-bottom: 15px;
  font-size: 14px;
  color: #333;
}

.security-question p {
  margin-bottom: 10px;
  font-weight: bold;
}

.security-question button {
  margin-left: 10px;
}

.success-message {
  color: green;
  font-size: 14px;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
}
