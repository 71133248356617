@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

.page-container {
    overflow: auto;
  }
  
  .providers-list-container {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    margin: 16px;
    font-family: "Roboto", sans-serif;
    color: #6C6C6C;
    width: 95%;
    transition: width 0.3s ease; 
    float: left;
  }
  
  .doctor-details-container {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    margin: 16px;
    transition: width 0.3s ease; 
    float: right;
    font-family: "Roboto", sans-serif;
    color: #6C6C6C;
  }
  
  .providers-list-container.shrink {
    width: 68%; 
  }
  
  .doctor-details-container.show {
    width: 20%; 
  }
.providers-list-table tr {
    cursor: pointer;
}

.providers-list-table tr:hover {
    background-color: #f7f7f7;
}

.active-row {
    background-color: #d6d6d6;;
  }

.providers-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.invite-button {
    background-color: #95CFDB;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 8px 16px;
    cursor: pointer;
    font-weight: bold;
}

.search-provider {
    border: 1px solid #D9D9D9;
    border-radius: 20px;
    padding: 8px;
    width: 90%;
    margin-bottom: 16px;
}

.search-button {
    background-color: #95CFDB;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 16px;
    cursor: pointer;
    font-weight: bold;
    margin-left: 10px; 
    width: 7%;
}

.close-button {
    background-color: #95CFDB;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 16px;
    cursor: pointer;
    font-weight: bold;
    margin-left: 10px; 
    width: 30%;
}

.providers-list-table {
    width: 100%;
    border-collapse: collapse;
}

.providers-list-table th, .providers-list-table td {
    text-align: left;
    padding: 12px;
}

.providers-list-table th {
    background-color: #F3F4F6;
}



.status-indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
}

.status-active {
    background-color: #28a745;
}

.status-inactive {
    background-color: #dc3545;
}