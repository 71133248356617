.account-container {
    display: flex;
    justify-content: center;
    align-items:start;
    min-height: 100vh;
    background-color: #f0f2f5;
    padding: 20px;
    padding-top: 50px;
}

.profile-card-container {
    display: flex;
    gap: 40px;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    max-width: 1000px; /* Adjust as needed */
}

.profile-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 45%; /* Adjust to make the cards fit side by side */
}

.profile-card h1 {
    color: #2a9df4;
    margin-bottom: 10px;
    text-align: center;
}

.profile-card .role {
    color: #666;
    margin-bottom: 20px;
    text-align: center;
}

.profile-card p {
    margin: 10px 0;
    color: #333;
}

.bio-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bio-text {
    flex-grow: 1;
}

.edit-icon-btn {
    background: none;
    border: none;
    cursor: pointer;
    color: #2a9df4;
    margin-left: 10px;
    flex-shrink: 0;
}

.bio-box {
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 10px;
}

.actions {
    margin-top: 20px;
    text-align: center;
}

.change-password-btn {
    background-color: #2a9df4;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin: 5px;
}

.change-password-btn:hover {
    background-color: #1a7fc2;
}

.bio-textarea {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-top: 10px;
    resize: vertical;
}

.account-container input {
    width: calc(100% - 30px);
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 10px;
}
