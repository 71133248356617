.container {
  background-color: #f5f5f5;
  font-family: "Roboto", sans-serif;

  position: relative;
  width: 100%;
  height: 100%;
}

.grid-container {
  display: "block";
}

.grid-content {
  width: 90% !important;
}

.inline-components-container {
  display: flex;
  flex-direction: row;
}

/* ------------------------------------
---------/ Date Range Filter /---------
-------------------------------------*/

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ffffff;
  z-index: 9999;
}

.date-range-filter {
  padding: 20px;
  display: 'flex';
  align-items: 'center';
}

.date-range-filter p {
  display: inline;
  padding-right: 10px;
}

/* ------------------------------------
----------/ Adherence Chart /----------
-------------------------------------*/

.adherence-chart-container {
  padding: 0 20px;
}

.adherence-chart {
  background-color: white;
  border-radius: 30px;
  padding: 5px 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

}

.chart-title {
  margin-left: 20px;
}

/* ------------------------------------
----------/ Overview Stats /-----------
-------------------------------------*/

.overview-stats {
  flex: 1;
}

/* ------------------------------------
----------/ ADR Pie Chart /------------
-------------------------------------*/
.adr-pie-container {
  flex: 1;
  margin: 20px 10px;
  background-color: white;
  border-radius: 15px;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.adr-title {
  font-size: 24px;
  color: #333;
}

.adr-select {
  width: 200px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: white;
  box-shadow: inset 0 1px 3px #e6e6e6;
  font-size: 16px;
  color: #333;
}

.pie-chart-container {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center;   /* Center items horizontally */
  margin-top: 20px;
}

.pie-chart-container p {
  margin-top: 10px; /* Add space between PieChart and text */
}


/* ------------------------------------
-------------/ Logs Table /------------
-------------------------------------*/

.logs-table-container {
  flex: 2;
  padding: 20px 0;
}

.logs-table-container table {
  width: 95%;
  border-collapse: collapse;
  border-radius: 10px;
  background-color: white;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.logs-table-container th, .logs-table-container td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.logs-table-container th {
  background-color: #ccc;
}

.logs-table-container tr:hover {
  background-color: #e8e8e8;
}

.logs-table-scrollable {
  height: 400px; /* Adjust the height as needed */
  overflow-y: scroll;
}

.arrowIcon {
  color: white;
  background-color: lightgray;
  border-radius: 15px;
}

.arrowIcon:hover {
  color: white;
  background-color: grey;
  border-radius: 15px;
}

/* ------------------------------------
-----------/ Patient Page /------------
-------------------------------------*/

.patient-overview-container {
  padding: 0 20px;
}

.overview-title {
  font-weight:normal;
  margin-bottom: 10px;
}

.bold-id {
  font-weight: bold;
}

.overview-phone {
  margin-bottom: 15px;
}

.overview-doses-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.doses-info p {
  padding: 5px 30px 5px 10px;
  border-radius: 15px;
  color: white;
}

.full-doses {
  background-color: #82ca9d;
}

.partial-doses {
  background-color: #ffc658;
  color: black; /* Ensure visibility */
}

.missed-doses {
  background-color: #ff6361;
}

.adherence-progress-bar {
  width: 30%;
  text-align: center;
}

.recent-adrs-title {
  margin-top: 20px;
}

.adrs-list {
  padding-left: 20px;
}

.patient-page-container {
  display: flex;
  flex-direction: row;
  font-family: "Roboto"
}

.patient-page-components {
  width: 50%;
  padding: 10px;
  margin: 10px;
}

.patient-logs-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.patient-logs-container table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 10px;
  background-color: white;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  max-height: 500px;
  display: block;
  overflow-y: auto;
}

.patient-logs-container th, .patient-logs-container td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.patient-logs-container th:nth-child(2),
.patient-logs-container td:nth-child(2) {
  min-width: 120px;
}

.patient-logs-container th {
  background-color: #ccc;
}

.patient-logs-container tr:hover {
  background-color: #e8e8e8;
}

.patient-logs-container td ul {
  list-style-type: none;
  padding-left: 0;
}

.patient-logs-container td ul li {
  margin-bottom: 5px;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.patient-logs-container::-webkit-scrollbar {
  width: 12px;
}

.patient-logs-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.patient-logs-container::-webkit-scrollbar-thumb {
  background: #888;
}

.patient-logs-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* ------------------------------------
-------------/ Export Data /------------
-------------------------------------*/

.export-data-container {
  padding: 2px;
  border-radius: 15px;
  text-align: center;
  margin-top: 2px;
}

.export-title {
  font-size: 14px;
  color: #777;
  margin-bottom: 5px;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.export-button {
  background-color: #95B9DB;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 15px;
  cursor: pointer;
  font-size: 12px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.export-button:hover {
  background-color: #95DBA1;
}

.export-button:active {
  background-color: #57a478;
}

.export-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8); /* semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's on top of other content */
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid #8884d8;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}