@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

.patient-list-container {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    margin: 16px;
    color:#6C6C6C;
    font-family: "Roboto", sans-serif;
  }
  
  .patient-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .invite-button {
    background-color: #95CFDB;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 8px 16px;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
}
  
  .search-patient {
    border: 1px solid #D9D9D9;
    border-radius: 20px;
    padding: 8px;
    width: 90%;
    margin-bottom: 16px;
  }
  
  .search-button {
    background-color: #95CFDB;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 16px;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    margin-left: 10px; 
    width: 7%;
  }
  
  .patient-list-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .patient-list-table th, .patient-list-table td {
    text-align: left;
    padding: 12px;
    font-family: "Roboto", sans-serif;


  }
  
  .patient-list-table th {
    background-color: #F3F4F6;
  }
  
  .progress-bar-background {
    background-color: #E0E0E0;
    border-radius: 20px;
    width: 100%;
    height: 20px;
  }
  
  .progress-bar-foreground {
    background-color: #72E0C5;
    height: 20px;
    border-radius: 20px;
    width: 0; /* This will be dynamically set inline based on completion percentage */
  }