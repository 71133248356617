@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

#background {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: #eeeeee;
}

#page-container {
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  background: white;
  display: flex;
  flex-direction: column;
  width: 500px;
  font-family: "Roboto", sans-serif;
}

.page-title {
  font-family: "Roboto", sans-serif;
  color: #95b9db;
}

.form-container {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 5px;
}

h5 {
  text-decoration: none;
  font-size: 13px;
  color: rgb(108, 108, 108);
  margin-bottom: 10px;
}



.form-container input[type="text"],
.form-container input[type="tel"],
.form-container input[type="email"],
.form-select {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  font-family: "Roboto", sans-serif;
  width: 480px;
}

.addNewProviderPageForm{
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  font-family: "Roboto", sans-serif;
  width: 480px;
}


.placeholder {
  text-decoration: none;
  color: rgb(108, 108, 108);
  opacity: 1;
}

.form-button {
  margin-top: 10px;
  padding: 12px;
  background-color: #95cfdb;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  width: 150px;
  align-self: center;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
}

.form-button:hover {
  background-color: #95dba1;
}

.auth-details {
  margin-top: 20px;
  padding: 15px;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: "Roboto", sans-serif;
}

.auth-title {
  font-size: 18px;
  color: #7292e0;
  margin-bottom: 10px;
}

.auth-details p {
  margin: 5px 0;
  
}

.auth-details a {
  color: #7292e0;
  text-decoration: none;
}

.auth-details a:hover {
  text-decoration: underline;
}

.qr-code-container {
  margin-top: 10px;
}